<template>
  <v-container
    style="height:92vh;overflow:auto; margin: auto; border-radius: 5px; background:rgba(255,255,255,.9 ); padding:15px;"
  >
    <!-- <v-card> -->
    <v-row
      no-gutters
      style="margin-bottom:15px; position:sticky; top:-15px; z-index:2; background:white;"
    >
      <v-col
        cols="12"
        class="d-flex"
        style="position:relative;padding:0 20px;text-align:center;margin-top:20px;"
      >
        <h6 class="indigo--text" style="font-weight:bold;margin:auto;">
          LOT DETAIL & MOVEMENT HISTORY
        </h6>
      </v-col>
    </v-row>
    <v-expansion-panels v-model="panel" multiple class="mb-1" elevation="1">
      <v-expansion-panel>
        <v-expansion-panel-header
          expand-icon="mdi-menu-down"
          style="font-weight: bold"
        >
          DETAIL LOT
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            v-if="result !== null"
            no-gutters
            style="padding: 0; margin-top:10px;"
          >
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    No. Lot
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="result.lot_no || '-'"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Sumber / Kepemilikan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="
                      `${result.company_name}${
                        result.location_name !== null
                          ? ' - ' + result.location_name
                          : ''
                      }` || '-'
                    "
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Department
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="result.department_name || '-'"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <!-- <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Sumber Lokasi
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="result.location_name || '-'"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col> -->
            <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Lokasi saat ini
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="result.current_company_name || '-'"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Gudang
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="result.current_location_name || '-'"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Terakhir dipindahkan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="
                      new Date(result.last_move_at).toLocaleDateString() || '-'
                    "
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Status
                  </p>
                </v-col>
                <v-col cols="2" style="padding: 0;">
                  <p
                    :style="
                      `border-bottom:1px solid grey; color:${statusColor(
                        result.status
                      )};`
                    "
                  >
                    {{ lotStatus(result.status) }}
                  </p>
                </v-col>
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header
          expand-icon="mdi-menu-down"
          style="font-weight: bold"
          >DETAIL ASET
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            v-if="result !== null"
            no-gutters
            style="padding: 0; margin-top:10px;"
          >
            <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Nama Aset
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="result.asset.name || '-'"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Kode
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="result.asset.code || '-'"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Kategori
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="result.asset.category_name || '-'"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Merk
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="result.asset.merk || '-'"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Total Stock
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="result.asset.total_stock || '-'"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Satuan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="result.asset.unit_name || '-'"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <!-- FAT Special access -->
            <v-row
              no-gutters
              v-if="
                getUserProfile.employee.company.plant_id === '6' &&
                  (getUserProfile.employee.department_id === 1 ||
                    getUserProfile.employee.department_id === 3)
              "
            >
              <v-col
                v-if="
                  this.getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '29'
                  ) !== undefined
                "
                cols="12"
                style="padding: 0"
              >
                <!-- !==
                  undefined && this.getUserProfile.employee.department_id === 1 -->
                <div style="padding: 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                  margin: 0;
                  position:relative;
                  left:-5px;
                  font-size: 14px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.3);
                "
                    >
                      Finance parameter
                    </p>
                  </v-col>
                </div>
              </v-col>
              <!-- <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Tgl. Pembelian
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    dense
                    :value="convertDate(result.purchase_date) || '-'"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col> -->

              <v-col
                v-if="
                  this.getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '29'
                  ) !== undefined
                "
                cols="12"
                md="3"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                    >
                      Model Aset
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      dense
                      :value="
                        detailFromOdoo !== null
                          ? detailFromOdoo.asset_model.name
                          : '-'
                      "
                      readonly
                      style="margin: 0"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="1" style="padding: 0"> </v-col>
              <v-col
                v-if="
                  this.getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '29'
                  ) !== undefined
                "
                cols="12"
                md="3"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                    >
                      Nilai Original
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      dense
                      :value="
                        detailFromOdoo !== null
                          ? price(detailFromOdoo.original_value)
                          : '-'
                      "
                      readonly
                      style="margin: 0"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="1" style="padding: 0"> </v-col>
              <v-col
                v-if="
                  this.getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '29'
                  ) !== undefined
                "
                cols="12"
                md="3"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                    >
                      Tgl. Akuisisi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      dense
                      :value="
                        detailFromOdoo !== null
                          ? convertDate(detailFromOdoo.acquisition_date)
                          : '-'
                      "
                      readonly
                      style="margin: 0"
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col
                v-if="
                  this.getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '29'
                  ) !== undefined
                "
                cols="12"
                md="3"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                    >
                      Metode
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      dense
                      :value="
                        detailFromOdoo !== null
                          ? detailFromOdoo.method.toUpperCase()
                          : '-'
                      "
                      readonly
                      style="margin: 0"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="1" style="padding: 0"> </v-col>
              <v-col
                v-if="
                  this.getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '29'
                  ) !== undefined
                "
                cols="12"
                md="3"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                    >
                      Durasi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      dense
                      :value="
                        detailFromOdoo !== null
                          ? detailFromOdoo.method_number +
                            ' ' +
                            period(detailFromOdoo.method_period)
                          : '-'
                      "
                      readonly
                      style="margin: 0"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="1" style="padding: 0"> </v-col>
              <v-col
                v-if="
                  this.getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '29'
                  ) !== undefined
                "
                cols="12"
                md="3"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                    >
                      Tgl. Depresiasi Pertama
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      dense
                      :value="
                        detailFromOdoo !== null
                          ? convertDate(detailFromOdoo.first_depreciation_date)
                          : '-'
                      "
                      readonly
                      style="margin: 0"
                    />
                  </v-col>
                </div>
              </v-col>

              <v-col
                v-if="
                  this.getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '29'
                  ) !== undefined
                "
                cols="12"
                md="5"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                    >
                      Sisa depresiasi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      dense
                      :value="
                        detailFromOdoo !== null
                          ? price(detailFromOdoo.value_residual)
                          : '-'
                      "
                      readonly
                      style="margin: 0"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="1" style="padding: 0"> </v-col>

              <v-col
                v-if="
                  this.getUserProfile.level.find(
                    ({ id }) => id === '1' || id === '29'
                  ) !== undefined
                "
                cols="12"
                md="5"
                style="padding: 0"
              >
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                    >
                      Nilai buku
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-text-field
                      dense
                      :value="
                        detailFromOdoo !== null
                          ? price(detailFromOdoo.book_value)
                          : '-'
                      "
                      readonly
                      style="margin: 0"
                    />
                  </v-col>
                </div>
              </v-col>
              <v-col cols="1" style="padding: 0"> </v-col>
            </v-row>
            <!-- -------------------- -->
            <!-- <v-col cols="12" md="6" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Nilai Depresiasi
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense :value="'-'" readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col> -->
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!-- Jurnal depresiasi -->
      <!-- <v-expansion-panel
        v-if="
          this.getUserProfile.level.find(({ id }) => id === '29') !==
            undefined && this.getUserProfile.employee.department_id === 1
        "
      >
        <v-expansion-panel-header
          expand-icon="mdi-menu-down"
          style="font-weight: bold"
        >
          JURNAL DEPRESIASI
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            v-if="journal !== null"
            no-gutters
            style="padding: 0; margin-top:10px;"
          >
            <v-col cols="12" style="padding: 0">
              <v-timeline dense>
                <v-timeline-item
                  v-for="(item, index) in journal"
                  :key="index"
                  :color="index % 2 === 0 ? 'indigo' : 'green'"
                >
                  <v-card
                    :color="index % 2 === 0 ? 'indigo' : 'green'"
                    dark
                    style="border:1px solid black; max-width:400px;"
                  >
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel> -->

      <v-expansion-panel>
        <v-expansion-panel-header
          expand-icon="mdi-menu-down"
          style="font-weight: bold"
          >RIWAYAT PEMINDAHAN
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row
            v-if="history !== null"
            no-gutters
            style="padding: 0; margin-top:10px;"
          >
            <v-col cols="12" style="padding: 0">
              <v-timeline dense>
                <v-timeline-item
                  v-for="(item, index) in history"
                  :key="index"
                  :color="index % 2 === 0 ? 'indigo' : 'green'"
                >
                  <v-card
                    :color="index % 2 === 0 ? 'indigo' : 'green'"
                    dark
                    style="border:1px solid black; max-width:400px;"
                  >
                    <v-card-text class="white text--primary">
                      <strong style="font-size:18px;">{{
                        convertDate(item.schedule_from)
                      }}</strong>
                      <br />
                      <strong>{{ item.destination_company_name }}</strong>
                      <h6 style="margin:0;">
                        Gudang: {{ item.placed_location_name }}
                      </h6>
                      <div class="text-caption">
                        <p style="margin:0;">Doc No : {{ item.doc_no }}</p>
                        <p style="margin:0;">
                          Diterima oleh : {{ item.received_by_name }}
                        </p>
                        <p style="margin:0;">
                          Tgl. Terima : {{ item.received_at }}
                        </p>
                        <p style="margin:0;">
                          Status :
                          <span v-if="item.is_sell == 0" style="color:green;"
                            >Moved</span
                          ><span v-if="item.is_sell == 1" style="color:red;"
                            >Sold</span
                          >
                        </p>
                      </div>
                      <v-btn
                        style="margin-top:10px;"
                        :color="index % 2 === 0 ? 'indigo' : 'green'"
                        outlined
                        @click="detailMovement(item.move_id)"
                      >
                        Lihat Dokumen
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- <hr /> -->
    <!-- </v-card> -->
    <div style="position:fixed; z-index:2; bottom:10px; right:0;">
      <Scanner @loadPage="detail" />
    </div>
  </v-container>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  components: {
    Scanner: () => import('../../home/components/Scanner.vue')
  },
  data() {
    return {
      asset: buildType.apiURL('asset'),
      odoo: buildType.apiURL('odoo'),
      panel: [0],
      reverse: false,
      result: null,
      history: null,
      journal: null,
      detailFromOdoo: null
    }
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  mounted() {
    this.detail()
  },
  methods: {
    ...mapActions([]),
    detail() {
      axios
        .get(`${this.asset}asset/lot/detail/${this.$route.params.id}`)
        .then(res => {
          console.log(res)
          this.lotHistory(res.data.data.id)
          this.result = res.data.data
          if (
            this.getUserProfile.level.find(
              ({ id }) => id === '1' || id === '29'
            ) !== undefined
          ) {
            this.getAssetDetailFromOdoo(
              res.data.data.asset.company_id,
              res.data.data.asset.code
            )
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    lotHistory(id) {
      axios
        .get(`${this.asset}movement/lot_history/${id}}`)
        .then(res => {
          this.history = res.data.data
        })
        .catch(err => {
          console.log(err)
        })
    },

    // convertDate(raw) {
    //   if (raw !== null) {
    //     // const date = raw.slice(0, raw.indexOf(' '))
    //     return raw
    //   }
    // },
    convertDate(raw) {
      const d = new Date(raw)
      const localDate = d.toLocaleString('id')
      const newDate = localDate.slice(0, localDate.indexOf(' '))
      return newDate
    },
    detailMovement(id) {
      this.$router.push(`/asset-management/detail-penerimaan/${id}`)
    },
    getAssetDetailFromOdoo(companyId, code) {
      // var odooCompanyId
      // switch (companyName) {
      //   case 'PT INDOMULTI MAS PERKASA':
      //     odooCompanyId = 1
      //     break
      //   case 'PT TIRTA MAS PERKASA':
      //     odooCompanyId = 2
      //     break
      //   case 'PT GRAHAMAS INTITIRTA':
      //     odooCompanyId = 3
      //     break
      //   case 'PT KENCANA ABADI JAYA':
      //     odooCompanyId = 4
      //     break
      //   case 'PT WAHANA INTI MAS':
      //     odooCompanyId = 5
      //     break
      //   case 'PT SANQUA MULTI INTERNATIONAL':
      //     odooCompanyId = 6
      //     break
      // }

      axios
        .get(`${this.odoo}account_asset/detail/${companyId}/${code}`)
        .then(res => {
          // console.log('detailodoo>>>', Object.keys(res.data).length)
          if (Object.keys(res.data).length > 0) {
            this.detailFromOdoo = res.data.data
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    price(val) {
      if (val !== undefined) {
        if (val !== null) {
          const reverse = val.toString()

          if (reverse.lastIndexOf('.') === -1) {
            const beforeComa1 = reverse
              .split('')
              .reverse()
              .join('')
            var ribuan1 = beforeComa1.match(/\d{1,3}/g)
            const result1 = ribuan1
              .join(',')
              .split('')
              .reverse()
              .join('')
            return result1
          } else {
            const beforeComa2 = reverse
              .slice(0, reverse.indexOf('.'))
              .split('')
              .reverse()
              .join('')
            var ribuan2 = beforeComa2.match(/\d{1,3}/g)
            const result2 = ribuan2
              .join(',')
              .split('')
              .reverse()
              .join('')
            const afterComa = reverse.slice(reverse.lastIndexOf('.') + 1)
            return result2 + '.' + afterComa
          }
        }
      }
    },
    period(val) {
      switch (val) {
        case '1':
          return 'Bulan'

        case '12':
          return 'Tahun'

        default:
          return ''
      }
    },

    lotStatus(pId) {
      switch (pId) {
        case -1:
          return 'Gone'
        case 1:
          return 'Available'

        case 2:
          return 'Sold'
      }
    },
    statusColor(pId) {
      switch (pId) {
        case -1:
          return 'red'
        case 1:
          return 'Green'

        case 2:
          return 'orange'
      }
    }
  }
}
</script>
